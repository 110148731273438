<!-- 出账页面 -->
<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="payout-container">
    <div class="title-container">
      <div>出账列表</div>
      <el-button class="common-screen-btn" @click="() => download()">导出</el-button>
    </div>
    <!-- 头部数据统计 -->
    <div class="common-Summary-container">
      <div class="Summary-item no-width">
        <div class="item-title">当日支出</div>
        <div @click="() => { collect(
                'payment-todayAmount',
                '当日支出',
                [],
                [
                  { name: '流水编号', colProp: ['code'] },
                  { name: '出账金额', colProp: ['amount'] },
                  { name: '出账状态', colProp: ['status'] },
                  { name: '出账类型', colProp: ['expenditureType'] },
                  { name: '收款人', colProp: ['transactionName'] },
                  { name: '出账方式', colProp: ['recordType'] },
                  { name: '出账账户', colProp: ['transactionAccount'] },
                  { name: '备注', colProp: ['remark'] },
                ]
              )}" class="item-num">
          {{ parseFloat(headerData.todayAmount).toFixed(2) || "--" }}
        </div>
      </div>

      <div class="Summary-item no-width">
        <div class="item-title">昨日支出</div>
        <div @click="() => { collect(
                'payment-yesterdayAmount',
                '昨日支出',
                [
                  
                ],
                [
                  { name: '流水编号', colProp: ['code'] },
                  { name: '出账金额', colProp: ['amount'] },
                  { name: '出账状态', colProp: ['status'] },
                  { name: '出账类型', colProp: ['expenditureType'] },
                  { name: '收款人', colProp: ['transactionName'] },
                  { name: '出账方式', colProp: ['recordType'] },
                  { name: '出账账户', colProp: ['transactionAccount'] },
                  { name: '备注', colProp: ['remark'] },
                ]
              )}" class="item-num">
          {{ parseFloat(headerData.yesterdayAmount).toFixed(2) || "--" }}
        </div>
      </div>
      <div class="Summary-item no-width">
        <div class="item-title">当月支出</div>
        <div @click="() => { collect(
                'payment-monthAmount',
                '当月支出',
                [
                ],
                [
                  { name: '流水编号', colProp: ['code'] },
                  { name: '出账金额', colProp: ['amount'] },
                  { name: '出账状态', colProp: ['status'] },
                  { name: '出账类型', colProp: ['expenditureType'] },
                  { name: '收款人', colProp: ['transactionName'] },
                  { name: '出账方式', colProp: ['recordType'] },
                  { name: '出账账户', colProp: ['transactionAccount'] },
                  { name: '备注', colProp: ['remark'] },
                ]
              )}" class="item-num">
          {{ parseFloat(headerData.monthAmount).toFixed(2) || "--" }}
        </div>
      </div>
      <div class="Summary-item no-width">
        <div class="item-title">当年支出</div>
        <div @click="() => { collect(
                'payment-yearAmount',
                '当年支出',
                [
                
                ],
                [
                  { name: '流水编号', colProp: ['code'] },
                  { name: '出账金额', colProp: ['amount'] },
                  { name: '出账状态', colProp: ['status'] },
                  { name: '出账类型', colProp: ['expenditureType'] },
                  { name: '收款人', colProp: ['transactionName'] },
                  { name: '出账方式', colProp: ['recordType'] },
                  { name: '出账账户', colProp: ['transactionAccount'] },
                  { name: '备注', colProp: ['remark'] },
                ]
              )}" class="item-num">
          {{ parseFloat(headerData.yearAmount).toFixed(2) || "--" }}
        </div>
      </div>
    </div>
    
    <div class="common-padding">
      <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <!-- 筛选条件 -->
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>流水编号:</span>
        <el-input
          v-model="params.code"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>收款人:</span>
        <el-input
          v-model="params.transactionName"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>创建人:</span>
        <el-input
          v-model="params.nickName"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <br/>
      <div class="common-input-container">
        <span>出账状态:</span>
        <el-select
          v-model="params.deleteFlag"
          placeholder="请选择"
          class="common-screen-input"
          @change="commonFun"
          clearable
        >
          <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>出账类型:</span>
        <el-select
          v-model="params.expenditureType"
          placeholder="请选择"
          class="common-screen-input"
          @change="commonFun"
          clearable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>出账方式:</span>
        <el-select
          v-model="params.recordType"
          placeholder="请选择"
          class="common-screen-input"
          @change="commonFun"
          clearable
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <common-date
          @commonGetDate="commonGetDate"
          ref="commonReset"
        ></common-date>
      </div>
      <el-button
        class="common-screen-btn mar"
        type="primary"
        @click="
          () => {
            commonFun();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="newAddEdit()"
      >新增出账</el-button
    >
    <!-- biaoge -->
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="code"
        label="流水编号"
        :formatter="tableColumn"
        width="160"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.deleteFlag == 0"
            type="text"
            size="small"
            @click="() => seeMore(scope.row)"
            >{{ scope.row.code }}</el-button
          >
          <el-button
            v-else-if="scope.row.deleteFlag == 1"
            type="text"
            size="small"
            class="del"
            @click="() => seeMore(scope.row)"
            >{{ scope.row.code }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="amount"
        label="出账金额"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="deleteFlag"
        label="出账状态"
        :formatter="tableColumn"
      >
        <template slot-scope="scope">
          <span
            :class="scope.row.deleteFlag == 0 ? 'deleteflag' : 'deleteflag2'"
            >{{ scope.row.deleteFlag == 0 ? "已出账" : "已作废" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="expenditureType"
        label="出账类型"
        :formatter="tableColumn"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.expenditureType == 'wages'">工资</span>
          <span v-if="scope.row.expenditureType == 'reimbursement'">报销</span>
          <span v-if="scope.row.expenditureType == 'supplierSettlement'"
            >供应商结算</span
          >
          <span v-if="scope.row.expenditureType == 'refund'">退款</span>
          <span v-if="scope.row.expenditureType == 'marketingManagement'"
            >市场营销</span
          >
          <span v-if="scope.row.expenditureType == 'fine'">罚款</span>
          <span v-if="scope.row.expenditureType == 'public relationsExpenses'"
            >公关费</span
          >
          <span v-if="scope.row.expenditureType == 'rent'">房租水电成本</span>
          <span v-if="scope.row.expenditureType == 'introduction'">介绍费</span>
          <span v-if="scope.row.expenditureType == 'tax'">税费</span>
          <span v-if="scope.row.expenditureType == 'socialSecurity'">社保费</span>
          <span v-if="scope.row.expenditureType == 'development'">研发费用</span>
          <span v-if="scope.row.expenditureType == 'other'">其他</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="paymentType"
        label="出账方式"
        :formatter="tableColumn"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.paymentType == "bankCard"
              ? "银行卡"
              : scope.row.paymentType == "alipay"
              ? "支付宝"
              : scope.row.paymentType == "wechat"
              ? "微信"
              : scope.row.paymentType == "cash"
              ? "现金"
              : "其他"
          }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="transactionName" label="收款人"> </el-table-column>
      <el-table-column
        prop="transactionPhone"
        label="联系方式"
        :formatter="tableColumn"
        width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="recordType"
        label="出账方式"
        :formatter="tableColumn"
        width="120px"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.recordType == 'bankCard'">银行卡</span>
          <span v-else-if="scope.row.recordType == 'alipay'">支付宝</span>
          <span v-else-if="scope.row.recordType == 'wechat'">微信</span>
          <span v-else-if="scope.row.recordType == 'cash'">现金</span>
          <span v-else-if="scope.row.recordType == 'corporateAccount'">对公账户</span>
          <span v-else-if="scope.row.recordType == 'generalAccount'">对公账户（总）</span>
          <span v-else-if="scope.row.recordType == 'publicAccount'">对公账户（分）</span>
          <span v-else-if="scope.row.recordType == 'taobao'">淘宝</span>
          <span v-else-if="scope.row.recordType == 'tiktok'">抖音</span>
          <span v-else-if="scope.row.recordType == 'pinduoduo'">拼多多</span>
          <span v-else-if="scope.row.recordType == 'other'">其他</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="transactionAccount"
        label="收款账户"
        :formatter="tableColumn"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="recordTime"
        label="出款时间"
        :formatter="tableColumn"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="createName"
        label="创建人"
        :formatter="tableColumn"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="tableColumn"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        :formatter="tableColumn"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    </div>

    <!-- 新增弹框 -->
    <el-dialog
      v-dialogDrag
      title="新增出账"
      :visible.sync="newAddVisible"
      width="520px"
      :append-to-body="true"
      :before-close="
        () => {
          close();
        }
      "
    >
      <el-form
        ref="form"
        :rules="rulesIncome"
        :model="seeMoreForm"
        label-position="left"
        label-width="100px"
      >
        <el-form-item label="出账金额" prop="amount">
          <el-input v-model.trim="seeMoreForm.amount" class="ipt2"></el-input>元
        </el-form-item>
        <el-form-item label="出账类型" prop="expenditureType">
          <el-select
            v-model="seeMoreForm.expenditureType"
            placeholder="请选择"
            class="ipt"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出账方式" prop="recordType">
          <el-select
            v-model="seeMoreForm.recordType"
            placeholder="请选择"
            class="ipt"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收款人" prop="transactionName">
          <el-autocomplete
            class="ipt"
            v-model.trim="seeMoreForm.transactionName"
            :fetch-suggestions="requestHuman"
            placeholder="请搜索"
            @select="handleSelect"
            :trigger-on-focus="false"
            clearable
            @clear="clearIpt"
          ></el-autocomplete>
          <!-- <el-input
            v-model.trim="seeMoreForm.transactionName"
            class="ipt"
          ></el-input> -->
        </el-form-item>
        <el-form-item label="收款账户" prop="transactionAccount">
          <el-input
            v-model.trim="seeMoreForm.transactionAccount"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="transactionPhone">
          <el-input
            v-model.trim="seeMoreForm.transactionPhone"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item label="出款时间" prop="recordTimeString">
          <el-date-picker
            v-model="seeMoreForm.recordTimeString"
            type="date"
            placeholder="选择日期"
            class="ipt"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="通知人" prop="noticeId">
          <el-select
            v-if="noticeList && noticeList.length"
            class="ipt"
            v-model="seeMoreForm.noticeId"
            placeholder="请选择通知人"
            multiple
          >
            <el-option
              v-for="item in noticeList"
              :key="item.id"
              :label="item.nickName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="seeMoreForm.remark"
            type="textarea"
            :rows="3"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="orderFile-container">
            <div class="orderFileBtn">
              上传附件
              <input
                class="orderFile-input"
                type="file"
                @input="
                  (e) => {
                    orderFile(e);
                  }
                "
              />
            </div>
            上传文件及图片，大小不超过50MB
          </div>
          <div class="fileList-container">
            <div
              v-for="(item, index) in fileList"
              :key="index"
              class="fileList-item"
            >
              <div class="item-name">
                <common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture>
              </div>
              <div
                @click="
                  () => {
                    deleteFlie(index);
                  }
                "
                class="deleteBtn"
              >
                删除
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="newAddVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              sendRequest();
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    
    <payout-detail
      ref="incomeDetail"
      :drawer="dialogVisible"
      :tableRow="tableRow"
      :loading="loading"
      :customerInfo="customerInfo"
      :adminInfo="adminInfo"
      :chanceFollowList="chanceFollowList"
      :childid="childid"
      @handleClose="handleClose"
      @getpayout="seeMore"
      @loadFun="loadFun"
      @payoutDetail="closeDialog"
      @getpaylist="getPayoutList"
      @updatetitle="getPayoutTitle"
    ></payout-detail>

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="report"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { tableColumn, Config, rulePrice } from "../../utils/index.js";
import { upload } from "../../service/upload.js";
import payoutDetail from "./children/payoutDetail";
import CommonDate from "../../components/common/commonDate";
import CommonPicture from "../../components/common/commonPicture.vue"
import {
  payoutList,
  payoutTitle,
  newPayout,
  orderNoticeList,
  payoutdetail,
  historyPeople
} from "../../service/money.js";
import CommonSumDialog from "../../components/common/commonSumDialog.vue"
import { report } from "../../service/home.js"

export default {
  name: "Payout",
  components: {
    Breadcrumb,
    payoutDetail,
    CommonDate,
    CommonPicture,
    CommonSumDialog
  },
  props: {},
  data() {
    return {
      Config,
      tableColumn,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "财务", isLink: false },
        { title: "出账列表", isLink: false }
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        page: 1,
        pageSize: 10
      },
      dialogVisible: false,
      newAddVisible: false, //新增弹框
      seeMoreForm: {
        amount: "",
        expenditureType: "",
        recordType: "",
        transactionName: "",
        transactionAccount: "",
        transactionPhone: "",
        recordTimeString: "",
        remark: "",
        noticeId: []
      },
      loading: false,
      tableData: [],
      options: [
        {
          value: "wages",
          label: "工资"
        },
        {
          value: "reimbursement",
          label: "报销"
        },
        {
          value: "supplierSettlement",
          label: "供应商结算"
        },
        {
          value: "refund",
          label: "退款"
        },
        {
          value: "marketingManagement",
          label: "市场营销"
        },
        {
          value: "fine",
          label: "罚款"
        },
        {
          value: "public relationsExpenses",
          label: "公关费"
        },
        {
          value: "rent",
          label: "房租水电成本"
        },
        {
          value: "introduction",
          label: "介绍费"
        },
        {
          value: "tax",
          label: "税费"
        },
        {
          value: "socialSecurity",
          label: "社保费"
        },
        {
          value: "development",
          label: "研发费用"
        },
        {
          value: "other",
          label: "其他"
        }
      ],
      options2: [
        {
          value: "generalAccount",
          label: "对公账户（总）"
        },
        {
          value: "publicAccount",
          label: "对公账户（分）"
        },
        {
          value: "taobao",
          label: "淘宝"
        },
        {
          value: "tiktok",
          label: "抖音"
        },
        {
          value: "pinduoduo",
          label: "拼多多"
        },
        {
          value: "bankCard",
          label: "银行卡"
        },
        {
          value: "alipay",
          label: "支付宝"
        },
        {
          value: "wechat",
          label: "微信"
        },
        {
          value: "cash",
          label: "现金"
        },
        {
          value: "other",
          label: "其他"
        }
      ],
      options3: [
        {
          value: "0",
          label: "已出账"
        },
        {
          value: "1",
          label: "已作废"
        }
      ],
      filesList: [], //上传文件
      tableRow: "", // 客户id用于编辑/查看
      customerInfo: "", // 客户详情
      adminInfo: "", // 系统详情
      chanceFollowList: "", // 跟进list
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company,
      headerData: {}, //头部数据
      noticeList: [],
      fileList: [],
      childid: "",
      rulesIncome: {
        amount: [
          { required: true, message: "不能为空", trigger: "change" },
          {
            validator: rulePrice,
            type: "number",
            trigger: "change",
            message: "请输入数字类型"
          }
        ],
        expenditureType: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        transactionName: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        recordType: [
          { required: false, message: "不能为空", trigger: "change" }
        ],
        transactionAccount: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        transactionPhone: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        recordTimeString: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        noticeId: [{ required: true, message: "不能为空", trigger: "change" }]
      },
      // 汇总弹窗所需数据
      commonSumVisible: false,
      report, // 列表接口方法
      hisHuman: [],//历史收款人信息
      timeout:  null
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 数据汇总弹窗start
    sumVisibleClose () {
      this.commonSumVisible = false
    },
    collect(type, name, screen, arr) { // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(type, name, screen, arr, this.params.companyId,'',this.options,this.options2);
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗end
    commonGetDate(startTime, endTime) {
      this.params.startDate = startTime;
      this.params.endDate = endTime;
      this.getPayoutList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getPayoutList();
    },
    commonFun(flag) {
      this.params.pageSize = 10;
      this.params.page = 1;
      this.getPayoutList();
      if (flag == true) {
        this.getPayoutTitle();
      }
    },
    async seeMore(row) {
      this.loading = true;
      this.childid = row.id;
      if(row.type == 'distributeSettle'){ //平台
        this.platform = "distributeSettle"
        // this.$refs.incomeDetail.orderWorkLog(row.id);
        // const { data } = await selectIncomeDetail({ disOrderNo: row.id });
        // this.tableRow = data;
      }
      const { data } = await payoutdetail({ flowId: row.id });
      this.$refs.incomeDetail.workLog(row.id);
      this.tableRow = data;
      this.dialogVisible = true;
      this.loading = false;
    },
    // 新增按钮
    newAddEdit() {
      this.newAddVisible = true;
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
      // this.getHisHuman()
    },
    // 确认按钮发请求
    async sendRequest() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          // 发送请求
          let params = { ...this.seeMoreForm };
          if (params.noticeId && params.noticeId.length) {
            params.noticeId = params.noticeId.join(",");
          } else {
            delete params.noticeId;
          }
          let fileList = [...this.fileList];
          params.fileUrls = fileList;
          if (params.fileUrls.length == 0) {
            delete params.fileUrls;
          }
          const res = await newPayout(params);
          if (res.code != 200) {
           return this.$message.error("操作失败！");
          }
          this.$message.success("操作成功！");
          // this.seeMoreForm = {};
          this.fileList = [];
          this.getPayoutList();
          this.getPayoutTitle();
          this.$refs.form.resetFields();
          this.newAddVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除按钮
    async deleteChance(id) {
      console.log(id);
      this.$message.success("删除成功");
    },
    resetBtn() {
      (this.params = {
        page: 1,
        pageSize: 10,
        companyId: this.rolesCompany[0].id
      }),
        this.$refs.commonReset.resetFun();
      this.getPayoutList();
    },
    // 上传文件
    async orderFile(e) {
      this.loading = true;
      let fileList = [...this.fileList];
      let file = e.target.files[0];
      let fileName = file.name;
      if (file.size / 1024 > 1024 * 50) {
        this.loading = false;
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        this.loading = false;
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      let resData = (await upload({ file: file })).data;
      // 上传接口
      // await payoutUpload({id: this.tableRow.id, filedId: resData.id, fileName: fileName, filedUrl: resData.filename})
      fileList.push({ fileName: fileName, fileUrl: resData.filename });
      this.fileList = fileList;
      e.target.value = "";
      this.loading = false;
    },
    deleteFlie(i) {
      let fileList = [...this.fileList];
      fileList = fileList.filter((item, index) => {
        return i != index;
      });
      this.fileList = fileList;
    },
    // 组件关闭前
    handleClose() {
      // this.resetBtn();
      this.getPayoutList();
      this.dialogVisible = false;
      // 关闭新增员工弹窗
      // done();
    },
    async getPayoutList() {
      // this.drawer = true;
      this.loading = true;
      let params = { ...this.params };
      const { data } = await payoutList(params);
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.tableData = data.list;
      this.loading = false;
    },
    loadFun(value) {
      this.loading = value;
    },
    close() {
      this.$refs.form.resetFields();
      this.newAddVisible = false;
    },
    async getPayoutTitle() {
      let id = this.params.companyId;
      const { data } = await payoutTitle({ companyId: id });
      this.headerData = data;
    },
    async getNoticeList() {
      let resData = (await orderNoticeList()).data;
      this.noticeList = resData;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    async getHisHuman(val){
      let params = {
        customerName: ''
      }
      if(val){
        params.customerName = val
      }else{
        delete params.customerName
      }
      const { data } = await historyPeople(params)
      this.hisHuman = data
    },
    download() {
      let params = { ...this.params };
      delete params.page;
      delete params.pageSize;
      let url = Config.api.url + "/financial/financialFlow/paymentListExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");

      window.location.href = url;
    },
    requestHuman(queryString,cb){
      if(queryString != ""){
        setTimeout(async()=> {
          let callBackArr = []; // 准备一个空数组，此数组是最终返给输入框的数组
          const { data } = await historyPeople({customerName: queryString})
          this.hisHuman = data
          if(data.length > 0){
            data.forEach((item)=>{
              if (item.transactionName.indexOf(queryString) >-1 ) { // 大于-1，只要包含就行，不再乎位置
               // 如果有具有关联性的数据
               callBackArr.push({value: item.transactionName,transactionAccount: item.transactionAccount,transactionPhone: item.transactionPhone}); // 就存到callBackArr里面准备返回呈现
              }
              if (callBackArr.length != 0) {
                cb(callBackArr);
              }
            })
          }
          else {
            cb([]);
          }
        }, 500)
      }
    },
    handleSelect(item) {
        this.seeMoreForm.transactionAccount = item.transactionAccount
        this.seeMoreForm.transactionPhone = item.transactionPhone
    },
    clearIpt(){
      this.seeMoreForm.transactionAccount = ""
      this.seeMoreForm.transactionPhone = ""
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany[0].id;
    }
    this.getPayoutTitle();
    this.getPayoutList();
    this.getNoticeList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.payout-container {
  text-align: left;
}
.common-input-container1 {
  width: 100px;
  display: inline-block;
  margin-right: 16px;
}
.ipt {
  width: 100% !important;
}
.ipt2 {
  width: 92% !important;
  margin-right: 10px;
}
.con-rec {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .addFileBtn {
    height: 20px;
    color: #1890ff;
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
    .addFileInput {
      position: absolute;
      left: 0;
      top: 0;
      width: 120px;
      height: 20px;
      opacity: 0;
    }
  }
}
.uploadimg {
  font-size: 12px;
  color: #ccc;
  margin-left: 8px;
}
/deep/.el-tag.el-tag--info {
  background-color: #1890ff !important;
  border-color: #e9e9eb !important;
  color: #ffffff !important;
}
/deep/.el-tag.el-tag--info .el-tag__close {
  border: 1px solid #fff !important;
}
/deep/.el-tag.el-tag--info .el-tag__close {
  color: #fff;
}
/deep/.el-select .el-tag__close.el-icon-close {
  background-color: transparent;
}
.orderFile-container {
  .orderFileBtn {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border: 1px solid rgba(220, 223, 230, 100);
    color: #666;
    background: #fff;
    text-align: center;
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    .orderFile-input {
      cursor: pointer;
      font-size: 0;
      width: 100px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
}
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
      font-size: 12px;
      color: #333;
    }
    .deleteBtn {
      color: #1890ff;
      cursor: pointer;
    }
  }
}
/deep/.el-table .cell {
  white-space: nowrap;
}
.mar {
  margin-left: 0;
}
.deleteflag {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid #66cc1f;
  color: #66cc1f;
}
.deleteflag2 {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;;
  border: 1px solid #b0b0b0;
  color: #b0b0b0;
}
.del {
  color: #ccc;
  text-decoration: line-through;
}
.no-width{
  width: 17%;
}
.title-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  align-items: center;
  position: relative;
  &::after {
    content: " ";
    background: #e6e6e6;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    bottom: -1px;
    z-index: 999999;
  }
  div {
    font-size: 24px;
    color: #101010;
  }
}
</style>
